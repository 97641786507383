import React from 'react';
import clsx from 'clsx';
import {Link as GatsbyLink} from 'gatsby';
import Link from '../../Shared/Link/Link';
import tintashLogoSrc from '../../../assets/images/connect/tintash-logo.png';
import Container from '../../Shared/Container/Container';
import {VARIANT_A_KEY, VARIANT_B_KEY} from '../Constants';

function WebHeadingSection({subtitles, title, variant}) {
  return (
    <section
      className={clsx({
        'connect__heading-section-ab': variant === 'A',
        'connect__heading-section': variant === 'B',
      })}
    >
      <Container>
        <div className="connect__heading-subsection">
          <GatsbyLink to="/" className="connect__heading-link">
            <img src={tintashLogoSrc} alt="Tintash Logo" />
          </GatsbyLink>
          <h1 className="connect__heading-subtitle">{title}</h1>

          {variant === 'B' && (
            <div className="connect__heading-title">
              Partner with Us and <span className="connect__heading-title--bold">Supercharge</span>{' '}
              Your <span className="connect__heading-title--bold">Growth</span>
            </div>
          )}
          {variant === 'A' && (
            <div className="connect__heading-title connect__heading-title--b">
              <span className="connect__heading-title--bold">We Design & </span> &amp;
              <br />
              <span className="connect__heading-title--bold">Build Products</span>
            </div>
          )}

          <h2 className="connect__heading-description">
            {variant === 'A' && subtitles[VARIANT_A_KEY]}
            {variant === 'B' && subtitles[VARIANT_B_KEY]}
          </h2>
          <Link className="connect__heading-btn" to="/contact" color="round" size="long">
            {variant === 'A' && 'CONTACT US'}
            {variant === 'B' && 'Build Your Team Today'}
          </Link>
        </div>
      </Container>
    </section>
  );
}

export default WebHeadingSection;
