import React from 'react';
import WebConnectPageTemplate from '../../../components/Connect/WebConnectPageTemplate';

const subtitles = {
  A: (
    <>
      Tintash is a mobile and web development company that provides managed remote teams from around
      the globe. We work closely with you to design and build your digital products. Our clients
      include several <span className="connect__heading-description-bold">Startups</span>,{' '}
      <span className="connect__heading-description-bold">Unicorns</span> and{' '}
      <span className="connect__heading-description-bold">Fortune 500s.</span>
    </>
  ),
  B:
    'Leverage our backend development talent to elevate your project. We help you achieve the dream outcomes you’ve always wanted.',
};
function index({location, pageContext}) {
  return (
    <WebConnectPageTemplate
      variant={pageContext.variant}
      location={location}
      title="Top 1% Web Backend Development Company"
      subtitles={subtitles}
      metaTitle="Top 1% Web Backend Development Company | Tintash"
      metaDescription="Tintash is an industry leading Web Application development company providing dedicated remote teams globally. We work closely with you to design and build your digital products. Our clients include Startups, Unicorns and Fortune 500s."
      pageviewTitle="Connect/Web/Web.Backend.Development"
    />
  );
}

export default index;
