import React, {useState, useCallback} from 'react';
import Cookies from 'js-cookie';
import {Helmet} from 'react-helmet';
import * as ReactGA from 'react-ga';
import '../../assets/css/connect.css';
import Footer from '../Shared/Footer';
import withUTMParams from '../HOC/withUTMParams';
import WebHeadingSection from './Section/WebHeadingSection_B';
import BuildTeamSection from './Section/BuildTeamSection';
import GrowthPartnerSection from './Section/GrowthPartnerSection';
import ClientsLogoSection from './Section/ClientsLogoSection';
import AwardsSection from './Section/AwardsSection';
import ClientsTestimonialSection from './Section/ClientsTestimonialSection';
import ContactUsSection from './Section/ContactUsSection';
import BlogPostsSection from './Section/BlogPostsSection';
import WhyClientPreferUsSection from './Section/WhyClientPreferUsSection';
import CookieBanner from '../Shared/CookieBanner';

import ServiceInfoSection from './Section/ServiceInfoSection';
import benefitsIcon from '../../assets/images/connect/service-info/benefits.svg';
import investingIcon from '../../assets/images/connect/service-info/investing.svg';

const SERVICE_INFO = [
  {
    title: <h2>Investing in Web Application Development</h2>,
    description:
      "Web application development services play a crucial role in today's digital landscape. They help build products that solve real-world customer problems creating user-friendly, responsive, and secure solutions that provide seamless experiences across different devices. By designing flexible tools accessible across devices, users can can capture valuable customer behavior, data, and market trends. Web application development is crucial for businesses of all sizes to reach global audiences, streamline processes, expand reach, and boost profits.",
    imgSrc: investingIcon,
  },
  {
    title: <h3>Create Industry-Leading Web Development Applications</h3>,
    description:
      'At Tintash, we specialize in web application development using React Js, Angular Js, Vue Js, ReactiveX, jQuery, ThreeJs, and more. Our team collaborates with your business to understand your needs, target audience, and define key features and functionality. Check out our portfolio to see our industry-leading web development applications!',
    imgSrc: benefitsIcon,
  },
];

function WebConnectPageTemplate({
  location,
  metaTitle,
  metaDescription,
  pageviewTitle,
  title,
  subtitles,
  variant = 'A',
}) {
  const [, setCookieState] = useState(false);

  const acceptCookieHandler = useCallback(() => {
    Cookies.set('permission-cookies', 'perm-granted', {secure: true}, {expires: 150});
    setCookieState(true);
  }, []);
  React.useEffect(() => {
    ReactGA.set({
      title: variant === 'A' ? pageviewTitle : `${pageviewTitle}.B`,
    });
    ReactGA.pageview(location.pathname);
  }, []);

  return (
    <div>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href="https://tintash.com/connect/web/web-development/" />
      </Helmet>
      <WebHeadingSection title={title} subtitles={subtitles} variant={variant} />
      <ClientsLogoSection />
      <ServiceInfoSection serviceInfo={SERVICE_INFO} />
      <WhyClientPreferUsSection />
      <GrowthPartnerSection />
      <BuildTeamSection />
      <BlogPostsSection />
      <AwardsSection />
      <ClientsTestimonialSection />
      <ContactUsSection />
      {!Cookies.get('permission-cookies') && <CookieBanner acceptHandler={acceptCookieHandler} />}
      <Footer showContactSection={false} showPortfolioSection={false} />
    </div>
  );
}

export default withUTMParams(WebConnectPageTemplate);
